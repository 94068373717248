import { m } from 'framer-motion';
import styled from 'styled-components';
import { colors } from '../../styles/atoms/colors';

export const ShopPerformanceContainer = styled.div`
  &.loading {
    visibility: hidden;
  }

  &.questionnaire {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    > :nth-child(2) {
      flex: 1 0 100%;
    }

    footer {
      padding-top: 33px;

      > :first-child {
        display: none;
      }
    }
  }

  &.results {
    position: relative;
    padding: 183px 100px 0;
    overflow: hidden;
    background-color: ${colors.gray[900]};
    color: white;

    @media print {
      padding: 0;
      color: black;
    }

    @media (max-width: 960px) {
      padding: 183px 34px 0;
    }

    @media (max-width: 768px) {
      padding: 120px 34px 0;
    }

    @media (max-width: 575px) {
      padding: 112px 24px 0;
    }
  }
`;

export const HeaderStyles = styled.div`
  padding: 16px 0;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--white);
  transition: top 0.35s cubic-bezier(0.4, 0, 0.2, 1);

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 6px;
    width: 100%;
    background: #e5e5e5;
  }

  &:after {
    transition: width 0.3s ease-out 0s;
    width: ${props => (props.step - 1) * (100 / props.questionCount)}%;
    background: #1c3fff;
  }

  .top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 6px;

    svg {
      width: 210px;
      height: 31px;
    }
  }
`;

export const QuestionContainer = styled.div`
  padding: 128px 0;
  overflow: hidden;
  background-color: #f0f1fa;
  --transition-duration: 0.15s;
  --transition-translate-left: translateX(-50px);
  --transition-translate-right: translateX(50px);

  @keyframes transition-out-next {
    from {
      transform: none;
      opacity: 1;
    }

    to {
      transform: var(--transition-translate-left);
      opacity: 0;
    }
  }

  &.transition-out-next .question-content {
    animation: transition-out-next var(--transition-duration) ease-in-out 0s
      both;
    pointer-events: none;
  }

  @keyframes transition-in-next {
    from {
      transform: var(--transition-translate-right);
      opacity: 0;
    }

    to {
      transform: none;
      opacity: 1;
    }
  }

  &.transition-in-next .question-content {
    animation: transition-in-next var(--transition-duration) ease-in-out 0s both;
    pointer-events: auto;
  }

  @keyframes transition-out-back {
    from {
      transform: none;
      opacity: 1;
    }

    to {
      transform: var(--transition-translate-right);
      opacity: 0;
    }
  }

  &.transition-out-back .question-content {
    animation: transition-out-back var(--transition-duration) ease-in-out 0s
      both;
    pointer-events: none;
  }

  @keyframes transition-in-back {
    from {
      transform: var(--transition-translate-left);
      opacity: 0;
    }

    to {
      transform: none;
      opacity: 1;
    }
  }

  &.transition-in-back .question-content {
    animation: transition-in-back var(--transition-duration) ease-in-out 0s both;
    pointer-events: auto;
  }

  @media (max-width: 1024px) {
    padding: 70px 0;
  }

  @media (max-width: 575px) {
    padding: 40px 0;
  }

  .question-content {
    max-width: 1160px;
    margin: 0 auto;
    padding: 0 100px;

    @media (max-width: 1024px) {
      max-width: 970px;
    }

    @media (max-width: 768px) {
      padding: 0 34px;
    }
  }

  h1 {
    font-style: normal;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 12px;
    line-height: 14.4px;
    color: #1c3fff;
  }

  .question-title {
    margin-bottom: 40px;
    font-style: normal;
    font-weight: 700;
    font-size: 28.13px;
    line-height: 1.2;
    text-align: center;
    letter-spacing: -0.3px;
    color: ${colors.gray[900]};

    @media (max-width: 575px) {
      margin-bottom: 20px;
      font-size: 22.5px;
      line-height: 1.1;
      letter-spacing: -0.27px;
    }

    @media (max-width: 400px) {
      font-size: 18px;
    }
  }

  .question-instructions {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 22px;
    letter-spacing: -0.16px;
    color: ${colors.gray[600]};

    @media (max-width: 400px) {
      font-size: 14.4px;
      margin-bottom: 20px;
    }
  }

  .question-answers {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 770px;
    margin: 0 auto;

    @media (max-width: 575px) {
      gap: 16px;
    }
  }

  .question-answer {
    padding: 16px 30px;
    text-align: left;
    font-weight: 400;
    font-size: 22.5px;
    line-height: 1.5;
    letter-spacing: -0.27px;
    color: ${colors.base.black};
    background-color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease 0s;

    @media (max-width: 575px) {
      padding: 14px 20px;
      font-size: 18px;
    }

    @media (max-width: 400px) {
      padding: 10px 20px;
      font-size: 14.4px;
    }

    @media (hover: hover) {
      &:hover {
        background-color: ${colors.primary[100]};
      }
    }

    &:focus-visible,
    &:active {
      background-color: ${colors.primary[100]};
    }

    &:active {
      transition: none;
    }
  }

  .back-button {
    display: flex;
    align-items: center;
    padding: 12px 18px;
    background: transparent;
    border: none;
    font-weight: 700;
    font-size: 12px;
    line-height: 1.2;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #7b848f;
    transition: transform 0.1s ease 0s;

    @media (max-width: 400px) {
      padding: 8px 12px;
      font-size: 10px;
    }

    &:hover,
    &:focus-visible {
      transform: scale(1.1);
    }

    svg {
      @media (max-width: 400px) {
        min-width: 16px;
        min-height: 16px;
        width: 16px;
        height: 16px;
      }
    }
  }

  .back-button-container {
    display: flex;
    justify-content: center;
    margin-top: 22px;

    @media (max-width: 400px) {
      margin-top: 18px;
    }
  }

  .back-button-label {
    margin-left: 12.17px;
    line-height: 20px;
  }
`;

export const ModalBody = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 32px;
  width: 570px;

  @media (max-width: 768px) {
    width: auto;
    padding: 32px 16px;
  }

  .heading {
    font-style: normal;
    font-weight: 700;
    font-size: 28.13px;
    line-height: 35px;
    text-align: center;
    letter-spacing: -0.4px;
    color: #171e2a;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .subhead {
    font-style: normal;
    font-weight: 400;
    font-size: 16.2px;
    line-height: 1.4;
    text-align: center;
    color: #2c3641;
    margin-bottom: 32px;
  }

  .form-container {
    position: relative;
    z-index: 2;
    text-align: left;

    .see-results-form {
      max-width: 454px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;
      background: white;

      @media (max-width: 768px) {
        padding: 32px 24px;
      }

      .form-group.required .control-label:after {
        content: '*';
        color: red;
      }

      .row {
        margin-left: -10px;
        margin-right: -10px;

        > [class*='col'] {
          padding-left: 10px;
          padding-right: 10px;
        }
      }

      .control-label {
        font-size: 16px;
        line-height: 19px;
      }

      input {
        border-radius: 3px;
        box-sizing: border-box;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        height: 48px;
        color: #171e2a;
        box-shadow: none;
      }

      .notice {
        font-size: 14.4px;
        line-height: 1.4;
        color: var(--subtle-text-color);
        margin-bottom: 0;
      }

      .select {
        height: 48px;
        text-align: left;
        border-radius: 3px;
        box-sizing: border-box;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        box-shadow: none;

        .MuiSelect-icon {
          right: 10px;
          top: calc(50% - 13px);
          pointer-events: none;

          &.MuiSelect-iconOpen {
            transform: none;
          }
        }

        div:first-of-type:focus {
          background-color: transparent !important;
        }

        &.is-invalid {
          svg {
            display: none;
          }
        }
      }

      button {
        text-transform: uppercase;
        box-shadow: none;
        padding: 15px 24px;
      }
    }
  }
`;

export const ResultsContainer = styled.div`
  margin: 0 auto;
  max-width: 770px;

  @page {
    margin: 10em 2em 0;
  }

  @media print {
    max-width: 100%;
  }

  .no-print {
    @media print {
      display: none;
    }
  }

  .results-hero {
    font-weight: 400;
    font-size: 18px;
    line-height: 1.5;
    text-align: center;
    letter-spacing: -0.27px;
    color: ${colors.gray[200]};

    @media print {
      color: black;
    }

    .page-heading-kicker {
      font-family: 'CircularXX';
      font-weight: 700;
      font-size: 12px;
      line-height: 1.2;
      letter-spacing: 2px;
      color: white;
      text-transform: uppercase;

      @media print {
        color: black;
      }
    }

    .page-heading {
      margin: 16px 0 0;
      font-family: 'CircularXX';
      font-style: normal;
      font-weight: 700;
      font-size: 55px;
      line-height: 1.1;
      letter-spacing: -0.3px;
      color: white;

      @media print {
        color: black;
      }

      @media (max-width: 768px) {
        font-size: 44px;
      }

      @media (max-width: 575px) {
        font-size: 36px;
      }
    }

    h2 {
      margin: 16px 0 0;
      font-family: 'CircularXX';
      font-weight: 600;
      font-size: 36px;
      line-height: 1.1;
      letter-spacing: -0.3px;
      color: white;

      @media print {
        color: black;
      }

      @media (max-width: 768px) {
        font-size: 28px;
        line-height: 1.2;
      }
    }

    .illustration {
      display: block;
      max-width: 100%;
      height: auto;
      margin: 16px auto 0;

      @media print {
        display: none;
      }

      &.print {
        display: none;

        @media print {
          display: block;
        }
      }
    }

    p {
      margin-top: 24px;

      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 1.5;
      }
    }
  }

  .highlight {
    color: ${colors.aqua[400]};
  }

  .cards {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media (max-width: 768px) {
      margin-top: 56px;
      gap: 30px;
    }

    @media (max-width: 575px) {
      margin-top: 30px;
    }
  }

  .action-buttons {
    margin: 30px 0;
    display: flex;
    gap: 16px;

    @media print {
      display: none;
    }

    @media (max-width: 575px) {
      gap: 18px;
      flex-direction: column;

      > .button {
        margin-right: auto;
      }
    }

    .bump-right {
      margin-left: auto;

      @media (max-width: 575px) {
        margin-left: 0;
      }
    }
  }

  .button {
    padding: 8px 15px;
    border-radius: 8px;
    display: flex;
    align-items: center;

    .icon {
      margin-right: 10px;
    }

    .label {
      display: inline-block;
      font-weight: 700;
      font-size: 11.5px;
      line-height: 1.4;
      letter-spacing: 0.26px;
      color: inherit;
    }
  }
`;

export const ResultCard = styled.div`
  padding: 30px 40px;
  border-radius: 8px;
  background-color: ${colors.gray[700]};
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.16px;
  transition: background-color 0.3s ease 0s;

  @media print {
    border: thin solid ${colors.gray[400]};
    background-color: transparent;
    height: auto !important;
  }

  @media (max-width: 768px) {
    padding: 20px;
    font-size: 14.4px;
  }

  &:not(.static) {
    cursor: pointer;

    &:hover {
      background-color: ${colors.gray[600]};

      .donut-chart::before {
        background-color: ${colors.gray[600]};
      }
    }
  }

  .icon {
    width: 36px;
    height: 36px;
    color: ${colors.gray[800]};

    @media print {
      color: ${colors.gray[300]};
    }
  }

  h3 {
    font-weight: 700;
    font-size: 28px;
    line-height: 1.2;
    letter-spacing: -0.3px;
    margin: 8px 0;

    @media (max-width: 768px) {
      font-size: 22px;
      line-height: 1.1;
    }
  }

  h4 {
    font-size: inherit;
    line-height: inherit;
    margin: 0;
  }

  p {
    margin: 0;
  }

  .breakdown {
    display: flex;
    gap: 48px;

    @media print {
      break-inside: avoid;
    }

    @media (max-width: 575px) {
      flex-direction: column;
      gap: 24px;
    }

    > * {
      @media print {
        opacity: 1 !important;
      }
    }
  }

  .score {
    width: 185px;
    position: relative;
    flex: 0 0 auto;

    @media (max-width: 768px) {
      width: 156px;
    }

    .details {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
    }

    .label {
      font-weight: 700;
      font-size: 12px;
      line-height: 1.2;
      letter-spacing: 2px;
      text-transform: uppercase;
    }

    .value {
      font-weight: 700;
      font-size: 28px;
      line-height: 1.05;
      letter-spacing: -0.3px;

      @media (max-width: 768px) {
        font-size: 22px;
      }
    }
  }

  .donut-chart {
    @property --gradient-end {
      syntax: '<percentage>';
      inherits: false;
      initial-value: 0%;
    }

    --gradient-end: 0%;
    width: 185px;
    height: 185px;
    border-radius: 100%;
    filter: blur(0.01px);
    background-image: conic-gradient(
      ${props => props.scoreColor} 0,
      ${props => props.scoreColor} var(--gradient-end),
      #e5e5e5 0,
      #e5e5e5 100%
    );
    transform: rotateY(180deg);
    transition: --gradient-end ${props => (props.score / props.total) * 2}s
      ease-in-out 0.25s;

    @media print {
      transition: none;
      --gradient-end: ${props => (props.score / props.total) * 100}%;
    }

    @media (max-width: 768px) {
      width: 156px;
      height: 156px;
    }

    &.show {
      --gradient-end: ${props => (props.score / props.total) * 100}%;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 114px;
      height: 114px;
      border-radius: 100%;
      background-color: ${colors.gray[700]};
      transition: background-color 0.3s ease 0s;

      @media print {
        background-color: white;
      }

      @media (max-width: 768px) {
        width: 96px;
        height: 96px;
      }
    }
  }

  .accordion {
    margin-top: 30px;
    border-top: thin solid ${colors.gray[400]};

    @media print {
      break-before: avoid;
    }

    .heading {
      color: ${colors.yellow[400]};
      padding-top: 20px;
      display: flex;
      gap: 10px;
      align-items: center;
      font-weight: 700;
      font-size: 16px;
      line-height: 1;

      @media print {
        color: black;
      }
    }

    .chevron {
      transition: transform 0.3s ease 0s;
      transform: none;

      &.flip {
        transform: rotateZ(-180deg);
      }
    }
  }
`;

export const RecommendationsContent = styled(m.div)`
  overflow: hidden;

  .recommendations {
    margin: 0;
    list-style: none;

    > li {
      margin-top: 16px;

      @media print {
        break-inside: avoid;
      }
    }
  }

  .number {
    font-weight: 700;
    font-size: 28px;
    line-height: 1.2;

    @media print {
      color: ${colors.gray[300]};
    }
  }
`;
